/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { createSelector } from 'reselect';
import { connect } from 'react-redux';
import CryptoJS from 'crypto-js';
import LearnLayout from '../components/layouts/learn';

import { userSelector } from '../redux/selectors';
import { verificationEmail } from '../redux/actions';
import { User } from '../redux/prop-types';

import './forgot-password.css';

type FormValues = {
  id: string;
};

type VerificationEmailProps = {
  id: string;
  verificationEmail: (formValues: FormValues) => void;
};

const mapStateToProps = createSelector(userSelector, (user: User) => ({
  user
}));

const mapDispatchToProps = {
  verificationEmail
};

function VerificationEmail(props: VerificationEmailProps) {
  const [code, setCode] = useState('');

  useEffect(() => {
    setCode(window.location.search.replace('?verification-code=', ''));
  }, [props]);

  function decryptData(encryptedData: string) {
    if (code) {
      const decrypted: string = CryptoJS.AES.decrypt(
        encryptedData,
        'devstartVerifiedEmail'
      ).toString(CryptoJS.enc.Utf8);
      return decrypted;
    } else {
      return 'codigo inválido';
    }
  }

  function verifiedEmail() {
    const { verificationEmail } = props;
    const idUser = decryptData(code);
    verificationEmail({ id: idUser });
  }

  return (
    <LearnLayout>
      <Helmet title='DEVStart | Verification email' />
      <div className='forgot-password-container'>
        <div className='forgot-password-box'>
          <div className='forgot-password-text-container'>
            <p id='forgot-password-text'>Ativar minha conta</p>
          </div>

          <div className='form-btn'>
            <button
              id='btn-recovery'
              onClick={verifiedEmail}
              className='btn-submit'
            >
              Confirmar
            </button>
          </div>
        </div>
      </div>
    </LearnLayout>
  );
}

VerificationEmail.displayName = 'VerificationEmailPage';

export default connect(mapStateToProps, mapDispatchToProps)(VerificationEmail);
